<template>
  <div>
    <div v-if="!loading" class="d-flex flex-wrap justify-space-evenly my-5">
      <v-card
        v-for="category in categories"
        :key="category._id"
        class="mx-auto mb20 d-flex flex-column align-content-start"
        outlined
        elevation="3"
        max-width="344"
        rounded="xl"
      >
        <ImageForCardWithTitle
          :image="category.image"
          :imageUrl="category.imageUrl"
          :title="category.title"
          main-theme-color="teal"
        />

        <v-card-actions v-if="category.touched" class="justify-space-between">
          <v-btn
            @click.prevent.stop="changeHandler(category)" text color="red">
            Сохранить (не сохранено)
          </v-btn>
        </v-card-actions>


        <v-card-subtitle class="flex-grow-1 teal--text text--lighten-1">
          {{ category.description }}
        </v-card-subtitle>

        <v-card-actions>
          <v-btn @click.stop="$router.push(`/services?id=${category._id}`)"
                 text color="teal">
            К процедурам
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            icon
            color="teal"
            @click="showCategoryForm(category)"
          >
            <v-icon>{{ category.show ? 'mdi-chevron-up' : 'mdi-lead-pencil' }}</v-icon>
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="category.show">
            <v-divider></v-divider>
            <v-form class="mx-5 my-5">
              <v-file-input
                v-model="category.imageFile"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Выбрать изображение"
                prepend-icon="mdi-file-image"
                label="Изображение для услуги"
                color="teal"
                @change="setPreviewUrl(category); findChangesAndSetTouched()"
              ></v-file-input>

              <v-text-field
                v-model="category.title"
                label="Наименование услуги"
                prepend-icon="mdi-pencil"
                color="teal"
                @keyup="findChangesAndSetTouched()"
              ></v-text-field>

              <v-text-field
                v-model="category.description"
                label="Краткое описание"
                prepend-icon="mdi-information"
                color="teal"
                @keyup="findChangesAndSetTouched()"
              ></v-text-field>

              <v-text-field
                v-model="category.minTotal"
                label="Минимальная стоимость услуги (руб)"
                prepend-icon="mdi-cash-100"
                color="teal"
                type="number"
                @keyup="findChangesAndSetTouched()"
              ></v-text-field>

              <v-switch
                v-model="category.is_available"
                color="teal lighten-1"
                inset
                label="Доступно для записи"
                @keyup="findChangesAndSetTouched()"
              ></v-switch>

              <v-card-actions class="justify-space-between">
                <v-btn
                  @click.prevent.stop="changeHandler(category)" text :color="category.touched ? 'red' : 'teal'">
                  Сохранить
                </v-btn>

                <v-btn @click.prevent.stop="deleteHandler(category)"
                       text color="error">
                  Удалить
                </v-btn>
              </v-card-actions>
            </v-form>
          </div>
        </v-expand-transition>
      </v-card>
    </div>


    <v-divider class="my-5" v-if="categories"></v-divider>

    <div class="d-flex flex-wrap justify-space-evenly" v-if="!loading">
      <div class="align-self-start justify-center flex-grow-1 mx-4">
        <h3>Создание новой услуги</h3>

        <v-form>
          <v-file-input
            v-model="imageFile"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Выбрать изображение"
            prepend-icon="mdi-file-image"
            label="Изображение для услуги"
            color="teal"
            @change="setImageUrl"
          ></v-file-input>

          <v-text-field
            v-model="title"
            label="Наименование услуги"
            prepend-icon="mdi-pencil"
            color="teal"
          ></v-text-field>

          <v-text-field
            v-model="description"
            label="Краткое описание"
            prepend-icon="mdi-information"
            color="teal"
          ></v-text-field>

          <v-text-field
            v-model="minTotal"
            label="Минимальная стоимость услуги (руб)"
            prepend-icon="mdi-cash-100"
            color="teal"
            type="number"
          ></v-text-field>

          <v-switch
            v-model="is_available"
            color="teal lighten-1"
            inset
            label="Доступно для записи"
          ></v-switch>

          <v-btn @click.prevent.stop="createHandler"
                 text color="teal">
            Создать
          </v-btn>

          <v-btn @click.prevent.stop="eraseHandler"
                 text color="teal">
            Очистить
          </v-btn>
        </v-form>
      </div>

      <div v-if="title || description || imageUrl" class="align-self-start mx-4">
        <div class="d-flex justify-center">
          <h3 class="mb-5 red--text">Предпросмотр</h3>
        </div>

        <div class="d-flex justify-center">
          <v-card class="my-4 elevation-5"
                  max-width="344"
                  rounded="lg">
            <ImageForCardWithTitle
              :imageUrl="imageUrl"
              :title="title"
              main-theme-color="teal"
            />

            <v-card-subtitle class="flex-grow-1 teal--text text--lighten-1">
              {{ description }}
            </v-card-subtitle>

            <v-card-actions>
              <v-btn text disabled>
                Предпросмотр
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageForCardWithTitle from "@/components/ImageForCardWithTitle";
export default {
  name: "Category",
  components: {ImageForCardWithTitle},

  data: () => ({
    loading: true,
    categories: [],
    services: [],
    category: "",

    title: "",
    description: "",
    minTotal: 0,
    is_available: true,
    imageFile: null,
    imageUrl: "",
    originalCategories: ""
  }),

  methods: {
    async loadCategoryList() {
      const json = await this.authFetch('/category/load-category-list-for-user', {})
      if (json.status === 200) {
        this.originalCategories = JSON.parse(JSON.stringify(json.categories))
        this.categories = json.categories.map(category => {
          category.show = false
          category.imageFile = null
          category.touched = false
          return category
        })
      }
    },

    async createHandler() {
      const formData = new FormData()
      formData.append('title', this.title.trim())
      formData.append('description', this.description.trim())
      formData.append('minTotal', this.minTotal)
      formData.append('is_available', this.is_available)
      formData.append('image', this.imageFile)

      let json = await this.authFetchFormData('/category/create-category', formData)
      if (json.status === 201)
        if (json.category) {
          const newCategory = json.category
          this.originalCategories.push(JSON.parse(JSON.stringify(newCategory)))
          newCategory.show = false
          newCategory.imageFile = null
          newCategory.imageUrl = false
          newCategory.touched = false
          this.categories.push(newCategory)
          this.title = ''
          this.description = ''
          this.minTotal = ''
          this.is_available = ''
          this.imageFile = null
          this.imageUrl = ''
          this.findChangesAndSetTouched()
        }
    },

    async changeHandler(category) {
      const formData = new FormData()
      formData.append('id', category._id)
      formData.append('title', category.title.trim())
      formData.append('description', category.description.trim())
      formData.append('minTotal', category.minTotal)
      formData.append('is_available', category.is_available)
      formData.append('image', category.imageFile)

      const json = await this.authFetchFormData(
        '/category/change-category',
        formData
      )
      if (json.status === 200) await this.updateCategoryList(json.category)
    },

    async updateCategoryList(updatedCategory) {
      if (updatedCategory) {
        this.originalCategories = this.originalCategories.map(oldCategory => {
          if (updatedCategory._id === oldCategory._id) return JSON.parse(JSON.stringify(updatedCategory))
          else return oldCategory
        })

        this.categories = this.categories.map(oldCategory => {
          if (updatedCategory._id === oldCategory._id) {
            updatedCategory.show = false
            updatedCategory.imageFile = null
            updatedCategory.imageUrl = false
            updatedCategory.touched = false
            return updatedCategory
          } else return oldCategory
        })

        this.originalCategories = JSON.parse(JSON.stringify(this.categories))
        this.findChangesAndSetTouched()
      }
    },

    async deleteHandler(category) {
      if (!window.confirm(
        `После удаления услуги, связанные с ней процедуры также станут недоступны, однако они продолжат отображаться в уже существующих записях клиентов, но возможность их редактирования будет утрачена.\n \nВы уверены что хотите удалить услугу "${category.title}"?`)
      ) return

      const json = await this.authFetch('/category/delete-category', {id: category._id})
      if (json.status === 200) await this.loadCategoryList()
    },

    eraseHandler() {
      this.title = ""
      this.description = ""
      this.minTotal = ""
      this.imageFile = null
      this.imageUrl = ""
    },

    setPreviewUrl(category) {
      if (category.imageFile) category.imageUrl = URL.createObjectURL(category.imageFile)
      else category.imageUrl = ''
    },

    setImageUrl() {
      if (this.imageFile) this.imageUrl = URL.createObjectURL(this.imageFile)
      else this.imageUrl = ''
    },

    eraseImageFieldHandler() {
      this.imageFile = ''
    },

    showCategoryForm(category) {
      category.show = !category.show
    },

    findChangesAndSetTouched() {
      for (let index in this.categories) {
        this.categories[index].touched = !!(this.categories[index].imageFile ||
          this.categories[index].imageUrl ||
          this.categories[index].title !== this.originalCategories[index].title ||
          this.categories[index].description !== this.originalCategories[index].description ||
          this.categories[index].is_available !== this.originalCategories[index].is_available ||
          this.categories[index].minTotal !== this.originalCategories[index].minTotal)
      }
    }
  },

  async mounted() {
    await this.loadCategoryList()
    this.loading = false
  }
}
</script>

<style scoped>

</style>
